import axios from 'axios';
import store from "../index";
import {BehaviorSubject} from "rxjs";

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export default {
    namespaced: true,
    state: {
        registration: new BehaviorSubject(null),
        notificationPermission: new BehaviorSubject(null),
        subscribedForPush: false,
        pushSubscription: null,
        badge: null
    },
    getters: {
        getNotificationPermission: (state) => {
            if (state.notificationPermission.getValue() === null) {
                store.dispatch('serviceWorker/requestNotificationPermission');
                store.dispatch('serviceWorker/listenForNotificationPermissionChanges');
            }

            return state.notificationPermission;
        }
    },
    mutations: {
        setRegistration(state, registration) {
            state.registration.next(registration);
        }
    },
    actions: {
        subscribeForPush({state, getters, dispatch}) {
            // Not in admin mode
            if (store.getters['auth/adminModeIsActive']) return;
            // Already subscribed
            if (state.pushSubscription !== null) return;
            if (state.subscribedForPush) return;

            const subscribe = () => {
                state.registration.subscribe((registration) => {
                    if (registration === null) return;
                    if (state.pushSubscription !== null) return;

                    const options = {
                        userVisibleOnly: true,
                        applicationServerKey: urlBase64ToUint8Array(
                            'BP_oyItdMS6P6U311Pl6ubp93XlzYRC4Oeh8UBiZEMPc_drmOir6exhQpi2sIscYV9PwViYcRk0LOHEBvWqur4c'
                        )
                    }

                    registration.pushManager.subscribe(options)
                        .then((subscription) => {
                            console.log('Push subscription was successful.');
                            state.pushSubscription = subscription;
                            dispatch('storePushSubscription', subscription);
                        });
                });
            }

            getters.getNotificationPermission
                .subscribe((permission) => {
                    if (permission === 'granted') {
                        subscribe();
                    }
                });

            state.subscribedForPush = true;
        },
        storePushSubscription({rootGetters}, subscription) {
            return axios.post(rootGetters.getUrl('/api/push/subscription'), subscription)
                .then(() => {
                    console.log('Push subscription stored successfully.');
                })
                .catch(error => {
                    console.log('Error storing push subscription.');
                    console.log(error);
                });
        },
        deletePushSubscription({state, rootGetters}) {
            if (state.pushSubscription === null) {
                return;
            }

            return axios.post(rootGetters.getUrl('/api/push/subscription/delete'), state.pushSubscription)
                .then(() => {
                    state.pushSubscription = null;
                    state.subscribedForPush = false;
                    console.log('Push subscription deleted successfully.');
                })
                .catch((error) => {
                    console.log('Error deleting push subscription.');
                    console.log(error);
                });
        },
        requestNotificationPermission({state}) {
            if (!('Notification' in window)) {
                // Check if the browser supports notifications
                state.notificationPermission.next('failed');
            } else if (['granted', 'denied'].includes(Notification.permission)) {
                // Check whether notification permissions have already been granted or denied;
                state.notificationPermission.next(Notification.permission);
            } else {
                // We need to ask the user for permission
                Notification.requestPermission().then((permission) => {
                    state.notificationPermission.next(permission);
                });
            }
        },
        listenForNotificationPermissionChanges({state}) {
            // If the user changes the permission state via browser functions
            if ('permissions' in navigator) {
                navigator.permissions.query({name: 'notifications'}).then(function (notificationPerm) {
                    notificationPerm.onchange = function () {
                        state.notificationPermission.next(notificationPerm.state);
                    };
                });
            }
        },
        setBadge({state}, value) {
            state.badge = value;
            if ('setAppBadge' in navigator && 'clearAppBadge' in navigator) {
                navigator.setAppBadge(value)
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        clearBadge() {
            if ('setAppBadge' in navigator && 'clearAppBadge' in navigator) {
                navigator.clearAppBadge().catch((error) => {

                    console.log(error);

                });
            }
        }
    },
    modules: {}
}
