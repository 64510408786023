<template>
  <banner text="Schüler-Portal" icon="graduation cap"/>

  <h2>Login</h2>
  <div class="ui segment">
    <form class="ui form success warning error" v-on:submit.prevent="login">
      <div class="ui warning message" v-if="userIsNotVerified">
        <p>
          Wir haben dir eine Mail zum Bestätigen deiner Email Adresse geschickt.
          Bitte klicke auf den Link in der Mail, damit du dich einloggen kannst.
        </p>
        <p>
          <button type="button" v-if="!emailVerificationLinkRequested"
                  v-bind:class="[requestingEmailVerificationLink ? 'ui loading button' : 'ui button']"
                  @click="requestEmailVerificationLink">
            neue Email anfordern
          </button>
          <button type="button" class="ui labeled icon green button" v-else>
            <i class="thumbs up icon"/>
            Link versendet
          </button>
        </p>
      </div>

      <div class="field">
        <label>E-mail</label>
        <input type="email" required v-model="loginForm.email" placeholder="maxmuster@mail.de"/>
      </div>
      <div class="field">
        <label>
          Passwort
          <sui-icon name="eye" @click="togglePasswordInputType"/>
        </label>
        <input :type="passwordInputType" required v-model="loginForm.password" placeholder="secret*123"/>
        <div class="d-flex justify-between flex-direction-row-reverse">
          <div>
            <small class="pointer" @click="toggleResetPasswordModal">
              Passwort vergessen?
            </small>
          </div>
          <div v-if="schoolExists">
            <small class="pointer" @click="resetSchool">
              Schule wechseln
            </small>
          </div>
        </div>
      </div>
      <template v-if="!schoolExists">
        <div class="field" :class="{error: wrongSchool}">
          <label>Schulkürzel</label>
          <input type="text" required v-model="schoolInput"/>
        </div>
        <div class="ui error message" v-if="wrongSchool">
          <p>Dieses Schulkürzel ist leider nicht korrekt.</p>
        </div>
      </template>
      <button type="submit" class="ui green button">Login</button>

      <div class="ui error message" v-if="loginFailure">
        <div class="header">Login fehlgeschlagen</div>
        <p>Deine Eingaben waren fehlerhaft, bitte versuche es erneut.</p>
      </div>

      <div class="ui error message" v-if="forgotPasswordFailureUnknownUser">
        <p>Es gibt keinen User mit der angegebenen Email Adresse.</p>
      </div>
      <div class="ui success message" v-if="forgotPasswordSuccess">
        <p>Wir haben dir einen Link zum Zurücksetzen deines Passworts geschickt. Bitte schau in dein Postfach.</p>
      </div>
    </form>
  </div>

  <sui-modal v-model="forgotPasswordModalOpen">
    <sui-modal-header>Passwort zurücksetzen</sui-modal-header>
    <sui-modal-content>
      <sui-modal-description>
        <p>
          Du hast dein Passwort vergessen? - Kein Problem!
        </p>
        <p>
          Wir schicken dir einen Link, mit dem du dein Passwort zurücksetzen kannst.
          Gib einfach deine Email Adresse ein und klicke auf Link versenden.
        </p>
      </sui-modal-description>

      <form id="reset-password-form" class="ui form error" v-on:submit.prevent="forgotPassword">
        <div class="field">
          <input type="email" required v-model="loginForm.email" placeholder="maxmuster@mail.de"/>
        </div>
      </form>
    </sui-modal-content>
    <sui-modal-actions>
      <sui-button type="button" @click="toggleResetPasswordModal">
        abbrechen
      </sui-button>
      <sui-button type="button" positive @click="forgotPassword">
        Link versenden
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
import store from "../store";
import Banner from "../components/Banner";

export default {
  components: {Banner},
  data: function () {
    return {
      loginForm: {
        email: null,
        password: null
      },
      passwordInputType: 'password',
      schoolInput: null,
      loginFailure: false,
      wrongSchool: false,
      emailVerificationLinkRequested: false,
      requestingEmailVerificationLink: false,
      forgotPasswordModalOpen: false,
      forgotPasswordSuccess: false,
      forgotPasswordFailureUnknownUser: false
    }
  },
  methods: {
    login: function () {
      if (this.schoolInput) {
        store.dispatch('auth/checkSchoolAndSet', this.schoolInput.trim().toLowerCase())
            .then(() => this.executeLogin())
            .catch(() => this.wrongSchool = true);
      } else {
        this.executeLogin()
      }
    },
    executeLogin: function () {
      store.dispatch('auth/login', this.loginForm)
          .then(() => this.$router.push('/'))
          .catch(() => this.loginFailure = true);
    },
    resetSchool: function () {
      store.state.auth.school = null;
    },
    toggleResetPasswordModal: function () {
      this.forgotPasswordModalOpen = !this.forgotPasswordModalOpen;
    },
    togglePasswordInputType: function () {
      if (this.passwordInputType === 'password') {
        this.passwordInputType = 'text';
      } else {
        this.passwordInputType = 'password';
      }
    },
    requestEmailVerificationLink: function () {
      if (this.schoolInput) {
        store.dispatch('auth/setSchool', this.schoolInput);
      }

      if (this.requestingEmailVerificationLink) return;

      this.requestingEmailVerificationLink = true;
      store.dispatch('auth/requestEmailVerificationLink')
          .then(res => {
            this.requestingEmailVerificationLink = false;
            if (res !== false) {
              this.emailVerificationLinkRequested = true;
            }
          })
          .catch(() => {
            this.requestingEmailVerificationLink = false;
          });
    },
    forgotPassword: function () {
      if (this.schoolInput) {
        store.dispatch('auth/setSchool', this.schoolInput);
      }

      this.forgotPasswordSuccess = false;
      this.forgotPasswordFailureUnknownUser = false;

      store.dispatch('auth/forgotPassword', this.loginForm.email)
          .then(() => this.forgotPasswordSuccess = true)
          .catch((error) => {
            if (
                error.response.status === 422
                && error.response.data.errors
                && error.response.data.errors.email
                && error.response.data.errors.email.includes('We can\'t find a user with that email address.')
            ) {
              this.forgotPasswordFailureUnknownUser = true;
            } else {
              alert('Fehler bei der Anfrage zum Zurücksetzen des Passworts. Bitte versuche es erneut.')
            }
          })
          .finally(() => this.loginFailure = false);
      this.toggleResetPasswordModal();
    }
  },
  computed: {
    userIsNotVerified() {
      return store.state.auth.verified === false;
    },
    schoolExists() {
      return store.state.auth.school !== null;
    }
  }
}
</script>
