<template>

  <banner text="Schüler-Portal" icon="graduation cap"/>

  <news v-if="displayNews"/>

  <sui-grid v-if="displayUnterricht || displayTermine || displayChats" class="mt-0">
    <sui-grid-row :columns="2">
      <sui-grid-column v-if="displayUnterricht">
        <router-link to="/unterricht">
          <sui-segment class="asam-orange" active-class="active">
            <sui-icon name="tasks" size="big"/>
            <p>Unterricht</p>
          </sui-segment>
        </router-link>
      </sui-grid-column>

      <sui-grid-column v-if="displayTermine">
        <router-link to="/termine">
          <sui-segment class="asam-blue" active-class="active">
            <sui-icon name="calendar alternate outline" size="big"/>
            <p>Termine</p>
          </sui-segment>
        </router-link>
      </sui-grid-column>

      <sui-grid-column v-if="displayChats">
        <router-link to="/chat-request">
          <sui-segment class="asam-green" active-class="active">
            <sui-icon name="rocketchat" size="big"/>
            <p>Kontaktanfrage</p>
          </sui-segment>
        </router-link>
      </sui-grid-column>
    </sui-grid-row>
  </sui-grid>

  <div class="ui success message" v-if="emailVerified">
    Die Email Adresse wurde verifiziert und ist ab sofort gültig.
  </div>

</template>

<script>
import store from '../store';
import Banner from "../components/Banner";
import News from "../components/News";

export default {
  name: 'Home',
  components: {Banner, News},
  data: function () {
    return {
      emailVerified: false
    }
  },
  methods: {},
  computed: {
    auth: function () {
      return store.getters['auth/check'];
    },
    authUserIsStudent: function () {
      return store.getters['auth/isStudent'];
    },
    displayNews: function () {
      return this.auth
          && store.getters['navigation/getSetup'].news
    },
    displayUnterricht: function () {
      return this.authUserIsStudent
          && store.getters['navigation/getSetup'].unterricht.content
    },
    displayTermine: function () {
      return this.authUserIsStudent
          && (
              store.getters['navigation/getSetup'].events.calendarEvents
              || store.getters['navigation/getSetup'].events.leistungsnachweise
          );
    },
    displayChats: function () {
      return this.authUserIsStudent
          && store.getters['navigation/getSetup'].chats;
    }
  },
  created() {
    if (this.$route.query.verified) {
      this.$router.push(this.$router.currentRoute.value.path);
      this.emailVerified = true;
      setTimeout(() => this.emailVerified = false, 10000);
    }
  }
}
</script>

<style scoped>
.ui.grid > .row > .column {
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>
