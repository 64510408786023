<template>
  <h2>Benachrichtigungen</h2>

  <div class="ui segment">

    <div v-if="notificationPermission === 'granted'" @click="showNotification"
         class="ui green segment pointer">
      <sui-icon name="bell" color="green" size="large"/>
      Benachrichtigungen sind aktiv. <br> Durch Klick hier kann das getestet werden.
    </div>

    <div v-else-if="notificationPermission === 'denied'"
         class="ui red segment">
      <sui-icon name="bell slash" color="red" size="large"/>
      Benachrichtigungen sind blockiert. Um Benachrichtigungen zu erhalten, muss das in den Browsereinstellungen geändert werden.
    </div>

    <div v-else-if="notificationPermission === 'failed'"
         class="ui red segment">
      <sui-icon name="bell slash" color="red" size="large"/>
      Dieser Browser unterstützt keine Benachrichtigungen.
    </div>

    <div v-else @click="requestNotificationPermission"
         class="ui orange segment pointer">
      <sui-icon name="bell" color="orange" size="large"/>
      Wir brauchen die Erlaubnis, Benachrichtigungen schicken zu dürfen.
    </div>

  </div>

</template>

<script>
import store from "../store";

export default {
  data: function () {
    return {
      notificationPermission: null
    }
  },
  methods: {
    requestNotificationPermission: function () {
      return store.dispatch('serviceWorker/requestNotificationPermission');
    },
    showNotification: function () {
      new Notification('Ich bin eine Benachrichtigung vom Schüler-Portal', {
        // "Visual Options",
        "body": "Ich bin der Body",
        "icon": "/favicon.svg",
        "badge": "/favicon.svg"
      });
    }
  },
  created() {
    store.getters['serviceWorker/getNotificationPermission'].subscribe((permission) => {
      this.notificationPermission = permission;
    })
  }
}
</script>
