<template>
  <h2>Profil</h2>

  <div class="ui segment">

    <h4 v-if="userHasPassword">Passwort ändern</h4>
    <h4 v-else>Passwort setzen</h4>

    <form class="ui form error success" v-on:submit.prevent="updatePassword">

      <div v-bind:class="[oldPasswordError ? 'field error' : 'field']" v-if="userHasPassword">
        <label>Altes Passwort</label>
        <input type="password" required v-model="updatePasswordForm.old_password" placeholder="secret*123"/>
      </div>
      <div class="ui error message" v-if="oldPasswordError">
        <p>Das alte Passwort ist nicht korrekt.</p>
      </div>

      <div v-bind:class="[passwordLengthError ? 'field error' : 'field']">
        <label>Neues Passwort</label>
        <input type="password" required v-model="updatePasswordForm.password" placeholder="secret*123"/>
      </div>
      <div class="ui error message" v-if="passwordLengthError">
        <p>Das Passwort muss mindestens 8 Zeichen lang sein.</p>
      </div>

      <div v-bind:class="[passwordConfirmationError ? 'field error' : 'field']">
        <label>Neues Passwort bestätigen</label>
        <input type="password" required v-model="updatePasswordForm.password_confirmation" placeholder="secret*123"/>
      </div>
      <div class="ui error message" v-if="passwordConfirmationError">
        <p>Die Bestätigung stimmt nicht mit dem Passwort überein.</p>
      </div>

      <button type="submit" class="ui green button">ändern</button>

      <div class="ui success message" v-if="updatePasswordSuccess">
        Das Passwort wurde aktualisiert.
      </div>

    </form>

  </div>

  <div class="ui segment">

    <h4>Email Adresse ändern</h4>

    <form class="ui form error success" v-on:submit.prevent="updateEmail">

      <div class="field">
        <label>aktuell</label>
        {{ userEmail }}
      </div>
      <div v-bind:class="[invalidEmailError || takenEmailError ? 'field error' : 'field']">
        <label>neu</label>
        <input type="email" required v-model="updateEmailForm.email" placeholder="maxmuster@mail.de"/>
      </div>

      <div class="ui error message" v-if="invalidEmailError">
        <p>Bitte gib eine gültige Email Adresse ein.</p>
      </div>
      <div class="ui error message" v-if="takenEmailError">
        <p>Diese Email Adresse existiert bereits.</p>
      </div>

      <button type="submit" class="ui green button">ändern</button>

      <div class="ui success message" v-if="updateEmailSuccess">
        Es wurde eine Email zum Bestätigen der neuen Adresse verschickt.<br>
        Erst mit Betätigen dieses Links wird die Änderung wirksam.
        Der Link ist eine Stunde lang gültig.
      </div>

    </form>

  </div>

</template>

<script>
import store from "../store";

export default {
  data: function () {
    return {
      updatePasswordForm: {
        old_password: null,
        password: null,
        password_confirmation: null,
      },
      updateEmailForm: {
        email: null
      },
      errors: null,
      updatePasswordSuccess: false,
      updateEmailSuccess: false
    }
  },
  methods: {
    updatePassword: function () {
      store.dispatch('auth/updatePassword', this.updatePasswordForm)
          .then(() => {
            this.errors = null;
            this.updatePasswordSuccess = true;
            setTimeout(() => this.updatePasswordSuccess = false, 5000);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              this.errors = {oldPassword: true}
            } else {
              this.errors = error.response.data.errors;
            }
          });
    },
    updateEmail: function () {
      store.dispatch('auth/updateEmail', this.updateEmailForm)
          .then(() => {
            this.errors = null;
            this.updateEmailSuccess = true;
            setTimeout(() => this.updateEmailSuccess = false, 5000);
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
    }
  },
  computed: {
    userEmail: function () {
      return store.state.auth.user.email;
    },
    userHasPassword: function () {
      return store.state.auth.user.hasPassword;
    },
    oldPasswordError: function () {
      return this.errors && this.errors.oldPassword;
    },
    passwordLengthError: function () {
      return this.errors && this.errors.password
          && this.errors.password.includes('The password must be at least 8 characters.');
    },
    passwordConfirmationError: function () {
      return this.errors && this.errors.password
          && this.errors.password.includes('The password confirmation does not match.');
    },
    invalidEmailError: function () {
      return this.errors && this.errors.email
          && this.errors.email.includes('The email must be a valid email address.');
    },
    takenEmailError: function () {
      return this.errors && this.errors.email
          && this.errors.email.includes('The email has already been taken.');
    }
  }
}
</script>
