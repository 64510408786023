import axios from 'axios';
import router from '../../router'

export default {
    namespaced: true,
    state: {
        school: null,
        schoolInfo: null,
        user: null,
        verified: null,
        sessionValidated: false,
        nonAuthRoutes: [
            'Login',
            'Register',
            'ResetPassword',
            'About'
        ]
    },
    getters: {
        check: (state) => {
            return state.user !== null;
        },
        sessionIsValidated: (state) => {
            return state.sessionValidated;
        },
        isAuthUser: (state) => (userId) => {
            return state.user.id === userId;
        },
        isNonAuthRoute: (state) => (route) => {
            return state.nonAuthRoutes.includes(route.path)
                || state.nonAuthRoutes.includes(route.name);
        },
        isIpUser: (state) => {
            return state.user && state.user.role === 'ip-user';
        },
        isStudent: (state) => {
            return state.user && state.user.role === 'student';
        },
        adminModeIsActive: (state) => {
            return state.user && state.user.adminMode;
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setVerified(state, verified) {
            state.verified = verified;
        }
    },
    actions: {
        checkSchoolAndSet({state, commit, dispatch}, school) {
            if (state.school === school) return;

            commit('config/addSchoolToServerUrl', school, {root: true});
            return dispatch('loadSchoolInfo')
                .then(() => dispatch('setSchool', school))
        },
        setSchool({state, commit, dispatch}, school) {
            if (state.school === school) return;

            state.school = school;
            document.cookie = 'schuelerportal_school=' + school + '; path=/; SameSite=Lax; secure; expires=Tue, 19 Jan 2038 03:14:07 UTC;'; // maximum cookie lifetime
            commit('config/addSchoolToServerUrl', school, {root: true});
            return dispatch('loadSchoolInfo');
        },
        loadSchool({state, dispatch}) {
            if (state.school) return true;

            const hasSchoolCookie = document.cookie.split('schuelerportal_school=')[1] !== undefined;
            if (hasSchoolCookie) {
                const schoolFromCookie = document.cookie.split('schuelerportal_school=')[1].split(';')[0];
                return dispatch('setSchool', schoolFromCookie);
            } else {
                return false;
            }
        },
        loadSchoolInfo({state, commit, rootGetters}) {
            commit('setLoading', true, {root: true});
            return axios.get(rootGetters.getUrl('/api/school'))
                .then(res => res.data)
                .then(data => state.schoolInfo = data)
                .finally(() => {
                    commit('setLoading', false, {root: true});
                });
        },
        login({dispatch, commit, rootGetters}, loginData) {
            commit('setLoading', true, {root: true});
            return new Promise((resolve, reject) => {
                axios.post(rootGetters.getUrl('/login'), loginData)
                    .then(() => dispatch('getUser'))
                    .then(resolve)
                    .catch((error) => {
                        commit('setUser', null);

                        // If token mismatch error fetch new csrf token and try again
                        if (error.response.status === 419) {
                            dispatch('getCsrfToken')
                                .then(axios.post(rootGetters.getUrl('/login'), loginData))
                                .then(() => dispatch('getUser'))
                                .then(resolve)
                                .catch(reject);
                        } else {
                            reject(error);
                        }
                    })
                    .finally(() => commit('setLoading', false, {root: true}));
            });
        },
        resolveLoginWithToken({dispatch}, query) {
            const makeLoginRequest = () => dispatch('loginWithToken', query.loginToken)
                .then(() => router.push(router.currentRoute.value.path))
                .catch(() => alert('Invalid token!'));

            if (query.school) {
                return dispatch('setSchool', query.school)
                    .then(makeLoginRequest);
            } else {
                return makeLoginRequest();
            }
        },
        loginWithToken({dispatch, commit, rootGetters}, token) {
            commit('setLoading', true, {root: true});

            return new Promise((resolve, reject) => {
                axios.post(rootGetters.getUrl('/api/login/token'), {token: token})
                    .then(() => dispatch('getUser'))
                    .then(resolve)
                    .catch((error) => {
                        commit('setUser', null);

                        // If token mismatch error fetch new csrf token and try again
                        if (error.response.status === 419) {
                            dispatch('getCsrfToken')
                                .then(axios.post(rootGetters.getUrl('/api/login/token'), {token: token}))
                                .then(() => dispatch('getUser'))
                                .then(resolve)
                                .catch(reject);
                        }
                    })
                    .finally(() => commit('setLoading', false, {root: true}));
            });
        },
        logout({commit, dispatch, rootGetters}) {
            commit('setLoading', true, {root: true});

            // Delete push subscription
            return dispatch('serviceWorker/deletePushSubscription', {}, {root: true})
                .then(() => axios.post(rootGetters.getUrl('/logout')))
                .finally(() => {
                    location.reload();
                });
        },
        requestEmailVerificationLink({rootGetters}) {
            return axios.post(rootGetters.getUrl('/email/verification-notification'))
                .catch(() => {
                    alert('Fehler beim Anfordern einer neuen Email. Bitte versuche es erneut.');
                    return false;
                });
        },
        forgotPassword({commit, rootGetters}, email) {
            commit('setLoading', true, {root: true});

            return axios.post(rootGetters.getUrl('/forgot-password'), {email: email})
                .finally(() => commit('setLoading', false, {root: true}));
        },
        resetPassword({commit, rootGetters}, data) {
            commit('setLoading', true, {root: true});

            return axios.post(rootGetters.getUrl('/reset-password'), data)
                .finally(() => commit('setLoading', false, {root: true}));
        },
        getRegistrationTokenInfo({commit, rootGetters}, token) {
            commit('setLoading', true, {root: true});

            return axios.get(rootGetters.getUrl('/api/registration-token/' + token))
                .then(res => res.data)
                .finally(() => commit('setLoading', false, {root: true}));
        },
        register({state, commit, rootGetters}, registrationData) {
            commit('setLoading', true, {root: true});

            return axios.post(rootGetters.getUrl('/register'), registrationData)
                .then((res) => {
                    state.verified = false;
                    return res;
                })
                .finally(() => commit('setLoading', false, {root: true}));
        },
        updatePassword({commit, dispatch, rootGetters}, newPasswordData) {
            commit('setLoading', true, {root: true});

            return axios.put(rootGetters.getUrl('/api/user/password'), newPasswordData)
                .then(() => dispatch('getUser'))
                .finally(() => commit('setLoading', false, {root: true}));
        },
        updateEmail({commit, rootGetters}, newEmailData) {
            commit('setLoading', true, {root: true});

            return axios.put(rootGetters.getUrl('/api/user/email'), newEmailData)
                .finally(() => commit('setLoading', false, {root: true}));
        },
        validateSession({state, dispatch}) {
            return dispatch('getUser')
                .finally(() => state.sessionValidated = true);
        },
        getUser({commit, rootGetters}) {
            commit('setLoading', true, {root: true});

            return axios.get(rootGetters.getUrl('/api/user'))
                .then(res => res.data)
                .then(user => {
                    commit('setUser', user);
                    commit('setVerified', true);
                })
                .catch(() => {
                    commit('setUser', null);
                })
                .finally(() => commit('setLoading', false, {root: true}));
        },
        getCsrfToken({commit, rootGetters}) {
            commit('setLoading', true, {root: true});

            return axios.get(rootGetters.getUrl('/sanctum/csrf-cookie'))
                .finally(() => commit('setLoading', false, {root: true}));
        }
    },
    modules: {}
}
